import React, { useState, useEffect, useRef } from 'react';
import { FaCogs, FaTint, FaRoad, FaUser, FaGasPump } from 'react-icons/fa';
import '../css/PopularCars.css';
import Logo from '../../../Images/logo.png';

import rava31 from '../../../Images/rava31.jpg';
import rava32 from '../../../Images/rava32.jpg';
import rava33 from '../../../Images/rava33.jpg';

import ist1 from '../../../Images/ist2.jpg';
import ist2 from '../../../Images/ist3.jpg';
import ist3 from '../../../Images/ist1.jpg';

import escudo1 from '../../../Images/escudo22.jpeg';
import escudo2 from '../../../Images/escudo2.jpg';
import escudo3 from '../../../Images/escudo3.jpg';

import juke1 from '../../../Images/juke1.jpg';
import juke2 from '../../../Images/juke2.jpg';
import juke3 from '../../../Images/juke3.jpg';

import rava451 from '../../../Images/rava451.jpg';
import rava452 from '../../../Images/rava452.jpg';
import rava453 from '../../../Images/rava453.jpg';

import rava41 from '../../../Images/vanguard2.jpeg';
import rava42 from '../../../Images/rava42.jpeg';
import rava43 from '../../../Images/rava43.jpeg';

import harrier1 from '../../../Images/harrier1.jpeg';
import harrier2 from '../../../Images/harrier2.jpeg';
import harrier3 from '../../../Images/harrier3.jpg';

import vanguard1 from '../../../Images/vanguard.jpeg';
import vanguard2 from '../../../Images/vanguard2new.jpeg';
import vanguard3 from '../../../Images/vanguard3.jpg';

import alphard1 from '../../../Images/alphard1.jpg';
import alphard2 from '../../../Images/alphard2.jpg';
import alphard3 from '../../../Images/alphard3.jpg';

import prado1 from '../../../Images/prado.jpeg';
import prado2 from '../../../Images/prado2.jpeg';
import prado3 from '../../../Images/prado3.jpeg';

import coster1 from '../../../Images/coster1.jpg';
import coster2 from '../../../Images/coster2.jpg';
import coster3 from '../../../Images/coster3.jpg';


const PopularCars = () => {
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [visible, setVisible] = useState(false);
  const carContainerRef = useRef(null);

  const carImages = {
    toyotaRav4Short: [rava32,rava31,rava33],
    toyotaist: [ist1,ist2,ist3],
    suzukiescudo: [escudo1,escudo2,escudo3],
    nissanjuke: [juke1,juke2,juke3],
    toyotarava45: [rava451,rava452,rava453],
    toyotarava4: [rava41,rava42,rava43],
    toyotaharrier: [harrier1,harrier2,harrier3],
    toyotavanguard: [vanguard1,vanguard2,vanguard3],
    toyotaalphard: [alphard1,alphard2,alphard3],
    toyotaprado: [prado1,prado2,prado3],
    toyotacoaster: [coster1,coster2,coster3],
  };

  const handlePrevImage = () => setActiveImageIndex(prevIndex => (prevIndex === 0 ? 2 : prevIndex - 1));
  const handleNextImage = () => setActiveImageIndex(prevIndex => (prevIndex === 2 ? 0 : prevIndex + 1));

  const cars = [
    {
      name: "TOYOTA RAVA4 (3 DOORS)",
      imageArray: carImages.toyotaRav4Short,
      rating: 5,
      transmission: "Automatic",
      fuel: "Petrol",
      drive: "Unlimited Millage",
      seats: "4 Persons",
      price: "$25",
    },
    {
      name: "TOYOTA IST",
      imageArray: carImages.toyotaist,
      rating: 5,
      transmission: "Automatic",
      fuel: "Petrol",
      drive: "Unlimited Millage",
      seats: "4 Persons",
      price: "$25",
    },
    {
      name: "SUZUKI ESCUDO",
      imageArray: carImages.suzukiescudo,
      rating: 5,
      transmission: "Automatic",
      fuel: "Petrol",
      drive: "Unlimited Millage",
      seats: "5 Persons",
      price: "$30",
    },
    {
      name: "NISSAN JUKE",
      imageArray: carImages.nissanjuke,
      rating: 5,
      transmission: "Automatic",
      fuel: "Petrol",
      drive: "Unlimited Millage",
      seats: "4 Persons",
      price: "$35",
    },
    {
      name: "TOYOTA RAVA4 (5 DOORS)",
      imageArray: carImages.toyotarava45,
      rating: 5,
      transmission: "Automatic",
      fuel: "Petrol",
      drive: "Unlimited Millage",
      seats: "5 Persons",
      price: "$30",
    },
    
    {
      name: "TOYOTA RAVA4 (5 DOORS)",
      imageArray: carImages.toyotarava4,
      rating: 5,
      transmission: "Automatic",
      fuel: "Petrol",
      drive: "Unlimited Millage",
      seats: "5 Persons",
      price: "$40",

      
    },
    {
      name: "TOYOTA HARRIER (5 DOORS)",
      imageArray: carImages.toyotaharrier,
      rating: 5,
      transmission: "Automatic",
      fuel: "Petrol",
      drive: "Unlimited Millage",
      seats: "5 Persons",
      price: "$45",
    },
    {
      name: "TOYOTA VANGUARD",
      imageArray: carImages.toyotavanguard,
      rating: 5,
      transmission: "Automatic",
      fuel: "Petrol",
      drive: "Unlimited Millage",
      seats: "5 Persons",
      price: "$45",
    },
    
    {
      name: "TOYOTA ALPHARD",
      imageArray: carImages.toyotaalphard,
      rating: 5,
      transmission: "Automatic",
      fuel: "Petrol",
      drive: "Unlimited Millage",
      seats: "7 Persons",
      price: "$50",
    },
    {
      name: "TOYOTA PRADO",
      imageArray: carImages.toyotaprado,
      rating: 5,
      transmission: "Automatic",
      fuel: "Petrol",
      drive: "Unlimited Millage",
      seats: "5 Persons",
      price: "$90",
    },
    {
      name: "TOYOTA COASTER",
      imageArray: carImages.toyotacoaster,
      rating: 5,
      transmission: "MANUAL",
      fuel: "Petrol",
      drive: "Unlimited Millage",
      seats: "28 Persons",
      price: "$120 per transfer",
    },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setVisible(entry.isIntersecting),
      { threshold: 0.1 }
    );
    if (carContainerRef.current) {
      observer.observe(carContainerRef.current);
    }
    return () => {
      if (carContainerRef.current) {
        observer.unobserve(carContainerRef.current);
      }
    };
  }, []);

  return (
    <div ref={carContainerRef} className={`popular-cars-container ${visible ? 'fade-in' : ''}`}>
      <head>
        <meta name="description" content="Discover the best rental cars in Zanzibar with a wide selection of vehicles. Find affordable and convenient options for your next adventure with Prestige Car Rental." />
<meta name="keywords" content="car rental, Zanzibar car hire, Toyota RAV4, Suzuki Escudo, Nissan Juke, car booking, vehicle rental, affordable car rental Zanzibar, best car rental Zanzibar, Zanzibar travel car hire" />
<meta name="author" content="Prestige Car Rental" />
<meta property="og:title" content="Popular Cars for Rent in Zanzibar - Prestige Car Rental" />
<meta property="og:description" content="Find the best rental cars for your trip to Zanzibar. Choose from a wide range of top-rated cars at affordable prices with Prestige Car Rental." />
<meta property="og:image" content="https://www.prestigecarrental.co.tz/images/logo.png" />
<meta property="og:url" content="https://www.prestigecarrental.co.tz/popular-cars" />
<meta property="og:type" content="website" />

<meta property="twitter:card" content="summary_large_image" />
<meta property="twitter:title" content="Popular Cars for Rent in Zanzibar - Prestige Car Rental" />
<meta property="twitter:description" content="Find the best rental cars for your trip to Zanzibar with Prestige Car Rental. Choose from a variety of affordable, top-rated cars." />
<meta property="twitter:image" content="https://www.prestigecarrental.co.tz/images/logo.png" />

      </head>
      <div className="popular-cars-header">
        <h2>Our Popular Cars</h2>
        <p>We offer the best selection of cars for your driving experience. Find the perfect ride for you.</p>
      </div>

      <div className="car-grid">
        {cars.map((car, index) => (
          <div key={index} className="car-card">
            <div className="car-image-slider">
              <img
                src={car.imageArray[activeImageIndex]}
                alt={car.name}
                className="car-image"
              />
              <div className="image-controls">
                <button className="image-btn" onClick={handlePrevImage}>←</button>
                <button className="image-btn" onClick={handleNextImage}>→</button>
              </div>
            </div>
            <div className="car-logo">
              <img src={Logo} alt="Car Logo" className="car-logo-img" />
            </div>
            <div className="car-info">
              <h3>{car.name}</h3>
              <div className="car-rating">
                <span className="rating">⭐⭐⭐⭐⭐ {car.rating}</span>
              </div>
              <div className="car-details">
                <div className="car-attributes">
                  <table>
                    <tr>
                    <td><div className="car-attribute"><FaCogs className="icon" /> {car.transmission}</div></td>
                    <td><div className="car-attribute"><FaGasPump className="icon" /> {car.fuel}</div></td>
                    </tr>
                    <tr>
                    <td><div className="car-attribute"><FaRoad className="icon" /> {car.drive}</div></td>
                    <td><div className="car-attribute"><FaUser className="icon" /> {car.seats}</div></td>
                    </tr>
                  </table>  
                </div>
              </div>
              <div className="car-price">{car.price} per day</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PopularCars;
