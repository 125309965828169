import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { Helmet } from 'react-helmet'; // For injecting meta tags into the head
import '../css/FaqComponent.css';

const FAQComponent = () => {
  const faqs = [
    { question: "What licence do I need to drive in Zanzibar?", answer: "All national driving licences are accepted in Zanzibar, but they must be presented with a Zanzibar Driving Permit." },
    { question: "What is a Zanzibar Driving Permit, and how do I get it?", answer: "A Zanzibar Driving Permit is required for non-residents to drive legally in Zanzibar. You can obtain it from rental agencies or the relevant authorities." },
    { question: "Can you pick me up from the airport, ferry, or my hotel?", answer: "Yes, we offer pick-up services from the airport, ferry terminal, and hotels." },
    { question: "I'm finished! Can I drop my car at the airport or ferry, or can you collect it from my hotel?", answer: "Yes, you can drop off the car at specific locations, or we can arrange a collection from your hotel." },
    { question: "I would like a driver, what's the cost and how do I arrange it?", answer: "You can request a driver when booking. The cost varies depending on the rental package." },
    { question: "What's the deal with fuel?", answer: "Cars come with a full tank, and we ask that they be returned the same way." },
    { question: "Can I drive on the beach?", answer: "No, driving on the beach is prohibited to preserve the natural environment." },
    { question: "What happens if I break down or get stuck?", answer: "Contact our support team immediately for assistance." },
    { question: "What happens if I have an accident?", answer: "Contact local authorities and inform us as soon as possible." },
    { question: "Will I get stopped by the police while driving in Zanzibar?", answer: "Police checkpoints are common, so please have your documents ready." },
    { question: "I want to see the sights of Zanzibar! Can you show us around?", answer: "We offer guided tours on request. Contact us for a sightseeing package." }
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <>
      {/* SEO Meta Tags */}
      <Helmet>
        <title>Frequently Asked Questions | Prestige Car Rental</title>
        <meta
          name="description"
          content="Find answers to common questions about car rentals in Zanzibar. Learn about permits, fuel policies, pick-ups, drop-offs, and more."
        />
        <meta
          name="keywords"
          content="Zanzibar car rental FAQ, Zanzibar Driving Permit, fuel policies, pick-up services, drop-off services, guided tours"
        />
        <meta name="author" content="Prestige Car Rental" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Frequently Asked Questions | Prestige Car Rental" />
        <meta
          property="og:description"
          content="Discover answers to your questions about car rental services in Zanzibar, including driving permits, fuel policies, and pick-up/drop-off services."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://prestigecarrental.co.tz/faq" />
        <meta property="og:image" content="https://prestigecarrental.co.tz/path-to-image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Frequently Asked Questions | Prestige Car Rental" />
        <meta
          name="twitter:description"
          content="Get all your Zanzibar car rental questions answered. Learn about permits, policies, and more with our comprehensive FAQ."
        />
        <meta name="twitter:image" content="https://prestigecarrental.co.tz/path-to-image.jpg" />
      </Helmet>

      {/* FAQ Content */}
      <div className="faq-container">
        <h2>Frequently Asked Questions</h2>
        <p>Got questions? Find answers to common inquiries about our car rental services here. Explore now!</p>
        <div className="faq-list">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className={`faq-item ${openIndex === index ? 'open' : ''} ${openIndex === index ? 'expanded' : ''}`}
              onClick={() => handleToggle(index)}
            >
              <div className="faq-question">
                <h3>{faq.question}</h3>
                <FaChevronDown />
              </div>
              <div className="faq-answer" style={{ display: openIndex === index ? 'block' : 'none' }}>
                <p>{faq.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default FAQComponent;
