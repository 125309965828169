import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './ContactUsContent.css';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaClock, FaDirections } from 'react-icons/fa';

const ContactUsContent = () => {
  const [formData, setFormData] = useState({ name: '', email: '', subject: '', message: '' });
  const [formStatus, setFormStatus] = useState({ submitting: false, success: null });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormStatus({ submitting: true, success: null });

    // Simulate form submission
    setTimeout(() => {
      setFormStatus({ submitting: false, success: true });
      setFormData({ name: '', email: '', subject: '', message: '' });
    }, 2000);
  };

  return (
    <div className="contact-section">
      <Helmet>
        <title>Contact Us | Presrige Car Rental- Car & Scooter Rentals in Zanzibar</title>
        <meta
          name="description"
          content="Reach out to Presrige Car Rental for inquiries about car and scooter rentals in Zanzibar. Contact us 24/7 for reliable and affordable rental services."
        />
        <meta
          name="keywords"
          content="Zanzibar car rentals, Presrige Car Rental, scooter rentals Zanzibar, contact IslandSerenityCars, car rental support, scooter rental inquiries"
        />
        <link rel="canonical" href="https://prestigecarrental/contactUs" />

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "PresrigeCarRental",
            "url": "https://prestigecarrental.co.tz",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+255 774 371 987",
              "contactType": "Customer Service",
              "areaServed": "TZ",
              "availableLanguage": "English"
            },
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Zanzibar",
              "addressCountry": "TZ"
            }
          })}
        </script>
      </Helmet>

      <div className="contact-container">
        <div className="contact-info">
          <h2 className="contact-header">Contact Us</h2>
          <p className="contact-description">
            We’re here to help! Get in touch with us for any inquiries or support.
          </p>
          <div className="contact-details">
            <p><FaPhone className="colored-icon phone-icon" /> +255 712 682 981</p>
            <p><FaEnvelope className="colored-icon email-icon" /> info@prestigecarrental.co.tz</p>
            <p>
              <FaMapMarkerAlt className="colored-icon location-icon" /> 
              Zanzibar, Tanzania
              <a 
                href="https://www.google.com/maps/place/Prestige+Car+Rental/@-6.1576727,39.1910765,17z/data=!3m1!4b1!4m6!3m5!1s0x185cd16a8f8aaae9:0xf793a9e0181455e7!8m2!3d-6.1576727!4d39.1936514!16s%2Fg%2F11y9qhlsft?entry=ttu&g_ep=EgoyMDI0MTIwNC4wIKXMDSoASAFQAw%3D%3D"
                target="_blank" 
                rel="noopener noreferrer" 
                className="map-link"
              >
                <FaDirections className="colored-icon directions-icon" /> 
              </a>
            </p>
            <p><FaClock className="colored-icon clock-icon" /> Mon - Sat: 24/7</p>
          </div>
        </div>

        <div className="contact-form">
          <h3 className="form-header">Send Us a Message</h3>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="subject"
              placeholder="Subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />
            <textarea
              name="message"
              placeholder="Your Message"
              rows="5"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            <button type="submit" className="submit-btn" disabled={formStatus.submitting}>
              {formStatus.submitting ? 'Sending...' : 'Send Message'}
            </button>
          </form>
          {formStatus.success === true && <p className="success-message">Message sent successfully!</p>}
          {formStatus.success === false && <p className="error-message">Failed to send message. Please try again later.</p>}
        </div>
      </div>
    </div>
  );
}

export default ContactUsContent;
