import Home from './Component/Home/Home';
import AboutUs from './Component/AboutUs/AboutUs';
import ContactUs from './Component/ContactUs/ContactUs';
import Booking from './Component/Booking/Booking';
import './App.css';

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <div className="App">
      <Helmet>
        {/* General site metadata */}
        <title>Prestige Car Rental - Zanzibar Rentals</title>
        <meta name="description" content="Prestige Car Rental offers reliable car and scooter rentals in Zanzibar. Explore our range of vehicles and book your ride today!" />
        <meta name="keywords" content="Zanzibar car rentals, scooter rentals, car hire Zanzibar, Zanzibar tours, car rental nearbyme, Zanzibar Express car Hire, ZanQuickcars, kayak, smilecars" />
        <meta name="author" content="Prestige Car Rental" />
        <link rel="canonical" href="https://www.prestigecarrental.co.tz/" />
        <link rel="icon" href="/favicon.ico?v=1" type="image/x-icon" />
        <link rel="shortcut icon" href="/favicon.ico?v=1" type="image/x-icon" />
      </Helmet>

      <Router>
        <Routes>
          <Route 
            path="/" 
            element={
              <>
                <Helmet>
                  <title>Home - Prestige Car Rental</title>
                  <meta name="description" content="Welcome to Prestige Car Rental - Your trusted partner for car and scooter rentals in Zanzibar. Discover our vehicles and explore Zanzibar!" />
                </Helmet>
                <Home />
              </>
            } 
          />
          <Route 
            path="/aboutUs" 
            element={
              <>
                <Helmet>
                  <title>About Us - Prestige Car Rental</title>
                  <meta name="description" content="Learn more about Prestige Car Rental, our mission, values, and why we're the top choice for rentals in Zanzibar." />
                  <meta name="keywords" content="About Prestige Car Rental, Zanzibar car rentals, Prestige Car Rental team" />
                </Helmet>
                <AboutUs />
              </>
            } 
          />
          <Route 
            path="/contactUs" 
            element={
              <>
                <Helmet>
                  <title>Contact Us - Prestige Car Rental</title>
                  <meta name="description" content="Get in touch with Prestige Car Rental for any questions or booking inquiries. We're here to assist you with your travel needs in Zanzibar!" />
                  <meta name="keywords" content="Contact Prestige Car Rental, Zanzibar car rentals contact, customer service" />
                </Helmet>
                <ContactUs />
              </>
            } 
          />
          <Route 
            path="/booking" 
            element={
              <>
                <Helmet>
                  <title>Booking - Prestige Car Rental</title>
                  <meta name="description" content="Book your car or scooter rental with Prestige Car Rental in Zanzibar. Choose from our wide selection of vehicles to suit your needs." />
                  <meta name="keywords" content="Booking, Zanzibar car rental booking, reserve a vehicle Zanzibar" />
                </Helmet>
                <Booking />
              </>
            } 
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
