import React from 'react';
import { Helmet } from 'react-helmet';
import Header from "../Home/js/Header";
import Footer from "../Home/js/Footer";
import HeroSection from "../Home/js/HeroSection";
import Buttons from "../Home/js/Button";
import { FaWhatsapp, FaEnvelope } from 'react-icons/fa';
import './Booking.css';

// Importing car images
import rava31 from '../../Images/rava31.jpg';
import ist1 from '../../Images/ist2.jpg';
import escudo1 from '../../Images/escudo2.jpg';
import juke1 from '../../Images/juke1.jpg';
import rava451 from '../../Images/rava451.jpg';
import rava41 from '../../Images/vanguard2.jpeg';
import harrier1 from '../../Images/harrier1.jpeg';
import vanguard1 from '../../Images/vanguard.jpeg';
import alphard1 from '../../Images/alphard1.jpg';
import prado1 from '../../Images/prado.jpeg';
import coster1 from '../../Images/coster1.jpg';

const cars = [
  {
    name: "TOYOTA RAV4 { 3 DOORS}",
    price: "$25",
    image: rava31,
    contactWhatsApp: "https://wa.me/+255712682981",
    contactEmail: "mailto:info@prestigecarrental.co.tz"
  },
  {
    name: "TOYOTA IST",
    price: "$25",
    image: ist1,
    contactWhatsApp: "https://wa.me/+255712682981",
    contactEmail: "mailto:info@prestigecarrental.co.tz"
  },
  {
    name: "SUZUKI ESCUDO",
    price: "$30",
    image: escudo1,
    contactWhatsApp: "https://wa.me/+255712682981",
    contactEmail: "mailto:info@prestigecarrental.co.tz"
  },
  {
    name: "NISSAN JUKE",
    price: "$35",
    image: juke1,
    contactWhatsApp: "https://wa.me/+255712682981",
    contactEmail: "mailto:info@prestigecarrental.co.tz"
  },
  {
    name: "TOYOTA RAV4",
    price: "$30",
    image: rava451,
    contactWhatsApp: "https://wa.me/+255712682981",
    contactEmail: "mailto:info@prestigecarrental.co.tz"
  },
  {
    name: "TOYOTA RAV4",
    price: "$40",
    image: rava41,
    contactWhatsApp: "https://wa.me/+255712682981",
    contactEmail: "mailto:info@prestigecarrental.co.tz"
  },
  {
    name: "TOYOTA HARRIER",
    price: "$45",
    image: harrier1,
    contactWhatsApp: "https://wa.me/+255712682981",
    contactEmail: "mailto:info@prestigecarrental.co.tz"
  },
  {
    name: "TOYOTA VANGUARD",
    price: "$45",
    image: vanguard1,
    contactWhatsApp: "https://wa.me/+255712682981",
    contactEmail: "mailto:info@prestigecarrental.co.tz"
  },
  {
    name: "TOYOTA ALPHARD",
    price: "$50",
    image: alphard1,
    contactWhatsApp: "https://wa.me/+255712682981",
    contactEmail: "mailto:info@prestigecarrental.co.tz"
  },
  {
    name: "TOYOTA PRADO",
    price: "$90",
    image: prado1,
    contactWhatsApp: "https://wa.me/+255712682981",
    contactEmail: "mailto:info@prestigecarrental.co.tz"
  },
  {
    name: "TOYOTA COASTER",
    price: "$120",
    image: coster1,
   contactWhatsApp: "https://wa.me/+255712682981",
    contactEmail: "mailto:info@prestigecarrental.co.tz"
  }
];

function Booking() {
  return (
    <div>
      <Helmet>
        <title>Prestige Car Rental  | Book Affordable Cars & Scooters</title>
        <meta
          name="description"
          content="Rent affordable cars and scooters in Zanzibar with Prestige Car Rental Rentals. Enjoy reliable, safe, and well-maintained vehicles for your island adventures."
        />
        <meta
  name="keywords"
  content="Zanzibar car rental, car hire Zanzibar, Prestige Car Rental Zanzibar, affordable car rentals Zanzibar, best car rental service Zanzibar, Zanzibar airport car rental, reliable car hire Zanzibar, cheap car hire Zanzibar, scooter rental Zanzibar, luxury car rental Zanzibar, car hire near me Zanzibar, self-drive car hire Zanzibar, Zanzibar 4x4 car rental, monthly car rental Zanzibar, Zanzibar car rental deals, Zanzibar tour car rentals, rent a car in Zanzibar, Zanzibar car booking online, Prestige car hire Zanzibar, Zanzibar travel car hire"
/>

        <link rel="canonical" href="https://prestigecarrental.co.tz/booking" />

        {/* Structured Data for SEO */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "CarRental",
            "name": "Prestige Car Rentals",
            "description": "Affordable car and scooter rentals in Zanzibar for a seamless travel experience.",
            "url": "https://prestigecarrental.co.tz",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Zanzibar",
              "addressCountry": "TZ"
            },
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+255712682981",
              "contactType": "Customer Service"
            }
          })}
        </script>
      </Helmet>

      <Header />
      <HeroSection />
      <div className="booking-container">
        <h2 className="booking-title">Car Booking</h2>
        <p className="booking-description">Select a car to book your ride now by contacting us through WhatsApp or Email.</p>
        <div className="booking-cars-container">
          {cars.map((car, index) => (
            <div key={index} className="booking-card">
              <img src={car.image} alt={car.name} className="booking-car-image" />
              <div className="booking-card-info">
                <h3>{car.name}</h3>
                <p className="booking-price">{car.price} per day</p>
                <div className="booking-buttons">
                  <a href={car.contactWhatsApp} target="_blank" rel="noopener noreferrer" className="whatsapp-btn">
                    <FaWhatsapp className="icon" />
                  </a>
                  <a href={car.contactEmail} target="_blank" rel="noopener noreferrer" className="email-btn">
                    <FaEnvelope className="icon" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
      <Buttons />
    </div>
  );
}

export default Booking;
