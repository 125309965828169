import React from 'react';
import { Helmet } from 'react-helmet';
import './AboutUsContent.css';

const AboutUsContent = () => {
  return (
    <section className="about-us">
      <Helmet>
        <title>About Us | Prestige Car Rental - Car & Scooter Rentals in Zanzibar</title>
        <meta
          name="description"
          content="Discover Prestige Car Rental, your trusted car and scooter rental service in Zanzibar. Affordable, safe, and quality rentals for unforgettable travels."
        />
        <meta
          name="keywords"
          content="Zanzibar car rentals, scooter rentals Zanzibar, Prestige Car Rental, affordable car rental Zanzibar, scooter rental Zanzibar, best car rentals in Zanzibar, Zanzibar travel rentals"
        />
        <meta name="author" content="Prestige Car Rental" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://prestigecarrental.co.tz/aboutUs" />
        
        {/* Social Media Optimization */}
        <meta property="og:title" content="About Us | Prestige Car Rental - Car & Scooter Rentals in Zanzibar" />
        <meta
          property="og:description"
          content="Prestige Car Rental offers the best car and scooter rental services in Zanzibar. Explore the island with affordable and reliable vehicles."
        />
        <meta property="og:url" content="https://prestigecarrental.co.tz/aboutUs" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://prestigecarrental.co.tz/assets/car-rental-og.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Us | Prestige Car Rental - Car & Scooter Rentals in Zanzibar" />
        <meta
          name="twitter:description"
          content="Reliable and affordable car and scooter rentals in Zanzibar. Book now with Prestige Car Rental!"
        />
        <meta name="twitter:image" content="https://prestigecarrental.co.tz/assets/car-rental-og.jpg" />
        
        {/* Structured Data for SEO */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "CarRental",
            "name": "Prestige Car Rental",
            "description": "Reliable car and scooter rentals in Zanzibar at affordable prices, ensuring memorable journeys.",
            "url": "https://prestigecarrental.co.tz/aboutUs",
            "logo": "https://prestigecarrental.co.tz/assets/logo.png",
            "sameAs": [
              "https://www.facebook.com/PrestigeCarRental",
              "https://www.instagram.com/PrestigeCarRental",
              "https://twitter.com/PrestigeCarRental"
            ],
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "123 Main Street",
              "addressLocality": "Zanzibar",
              "addressCountry": "TZ"
            },
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+255 123 456 789",
              "contactType": "Customer Service",
              "email": "info@prestigecarrental.co.tz"
            }
          })}
        </script>
      </Helmet>
      
      <div className="about-us-container">
        <h1 className="about-us-header">About Us</h1>
        <p className="about-us-description">
          Welcome to Prestige Car Rental, your reliable partner in exploring Zanzibar with ease and comfort. We are dedicated to providing you with the best car and scooter rental experience at affordable prices, ensuring that your adventures on this beautiful island are unforgettable.
        </p>

        <div className="about-us-mission">
          <h2 className="about-us-subheader">Our Mission</h2>
          <p>
            Our mission is to offer top-quality, well-maintained cars and scooters at competitive prices, backed by exceptional customer service. We aim to make your journey through Zanzibar smooth, safe, and enjoyable.
          </p>
        </div>

        <div className="about-us-vision">
          <h2 className="about-us-subheader">Our Vision</h2>
          <p>
            Our vision is to become the leading car and scooter rental company in Zanzibar, known for our commitment to quality, safety, and customer satisfaction.
          </p>
        </div>

        <div className="about-us-values">
          <h2 className="about-us-subheader">Our Values</h2>
          <ul className="values-list">
            <li>Customer Satisfaction: We prioritize the happiness and satisfaction of our customers.</li>
            <li>Safety: We maintain our vehicles to the highest safety standards.</li>
            <li>Integrity: We operate with honesty and transparency in all our dealings.</li>
            <li>Innovation: We continuously improve our services to meet the changing needs of our customers.</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default AboutUsContent;
