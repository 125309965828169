import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../css/WhyChooseUs.css';
import { FaHeadset, FaCheckCircle, FaCar, FaCarSide } from 'react-icons/fa';

const WhyChooseUs = () => {
  const [isVisible, setIsVisible] = useState(false);
  const featureCards = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('show');
          }
        });
      },
      {
        threshold: 0.2, // Trigger when 20% of the card is in view
      }
    );

    featureCards.current.forEach(card => observer.observe(card));

    return () => observer.disconnect(); // Clean up the observer when component unmounts
  }, []);

  return (
    <section className="why-choose-us">
      <Helmet>
        <title>Why Choose Prestige Car Rental for Car and Scooter Rentals in Zanzibar</title>
        <meta name="description" content="Discover the best car and scooter rental services in Zanzibar with Prestige Car Rental. Our fleet of well-maintained vehicles and 24/7 support ensures a seamless travel experience." />
        <meta name="keywords" content="car rental Zanzibar, scooter rental Zanzibar, best car and scooter rental Zanzibar, vehicle hire Zanzibar, travel services Zanzibar, explore Zanzibar" />
        
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Why Choose Prestige Car Rental for Car and Scooter Rentals in Zanzibar" />
        <meta property="og:description" content="Discover the best car and scooter rental services in Zanzibar with Prestige Car Rental. Our fleet of well-maintained vehicles and 24/7 support ensures a seamless travel experience." />
        <meta property="og:image" content="path_to_image.jpg" /> {/* Add relevant image path */}
        <meta property="og:url" content="https://www.prestigecarrental.co.tz/why-choose-us" /> {/* Actual URL */}
        <meta property="og:type" content="website" />
        
        {/* Twitter Meta Tags */}
        <meta name="twitter:title" content="Why Choose Prestige Car Rental for Car and Scooter Rentals in Zanzibar" />
        <meta name="twitter:description" content="Discover the best car and scooter rental services in Zanzibar with Prestige Car Rental. Our fleet of well-maintained vehicles and 24/7 support ensures a seamless travel experience." />
        <meta name="twitter:image" content="path_to_image.jpg" /> {/* Add relevant image path */}
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="container">
        <h2>Why Choose Our Car and Scooter Rental Services in Zanzibar?</h2>
        <p className="subtitle">
          Experience unmatched convenience, quality, and support with our top-rated car and scooter rental services.
        </p>
        <div className="features">
          <div className="feature-card" ref={(el) => featureCards.current.push(el)}>
            <FaHeadset className="feature-icon" />
            <h3>24/7 Customer Support</h3>
            <p>Our dedicated team is here to assist you anytime, anywhere, ensuring a hassle-free experience.</p>
          </div>
          <div className="feature-card" ref={(el) => featureCards.current.push(el)}>
            <FaCheckCircle className="feature-icon" />
            <h3>Simple Booking Process</h3>
            <p>Effortlessly book your scooter or car in just a few clicks and enjoy a seamless rental experience.</p>
          </div>
          <div className="feature-card" ref={(el) => featureCards.current.push(el)}>
            <FaCar className="feature-icon" />
            <h3>Diverse Range of Cars</h3>
            <p>Select from an extensive variety of cars tailored for every transportation need during your trip.</p>
          </div>
          <div className="feature-card" ref={(el) => featureCards.current.push(el)}>
            <FaCarSide className="feature-icon" />
            <h3>High-Quality, Well-Maintained Vehicles</h3>
            <p>We offer a fleet of top-quality, well-maintained cars for a smooth and enjoyable ride throughout Zanzibar.</p>
          </div>
        </div>
        <a href="/booking" className="cta-button">Book Your Ride Now</a>
      </div>
    </section>
  );
};

export default WhyChooseUs;
