import React from 'react';
import { Helmet } from 'react-helmet';
import '../css/Footer.css'; // Ensure you keep this CSS file for styling
import {
  FaPhone,
  FaEnvelope,
  FaGlobe,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaMapMarkerAlt
} from 'react-icons/fa';

const Footer = () => {
  return (
    <>
      <Helmet>
        <title>Prestige Car Rental - Zanzibar Car and Scooter Rentals</title>
        <meta
          name="description"
          content="Contact Prestige Car Rental for trusted car and scooter rentals in Zanzibar. Explore the island with ease and flexibility."
        />
        <meta
          name="keywords"
          content="Zanzibar car rentals, scooter rentals Zanzibar, Island Serenity Cars, Zanzibar travel, Prestige Car Rental"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "CarRental",
              "name": "Prestige Car Rental",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Vehicle Avenue",
                "addressLocality": "Zanzibar",
                "addressRegion": "TZ",
                "postalCode": "00000",
                "addressCountry": "Tanzania"
              },
              "telephone": "+255714187068",
              "email": "info@prestigecarrental.co.tz",
              "url": "https://www.prestigecarrental.co.tz",
              "sameAs": [
                "https://facebook.com/",
                "https://twitter.com/",
                "https://instagram.com/islandserenitycar"
              ]
            }
          `}
        </script>
      </Helmet>

      <footer
        className="footer"
        aria-label="Footer with company information and contact details for Island Serenity Cars"
      >
        <div className="footer-section footer-logo">
          <h2>Prestige Car Rental - Zanzibar Rentals</h2>
          <p>Your trusted partner for car and scooter rentals in Zanzibar. Experience Zanzibar with ease and flexibility.</p>
        </div>

        <address
          className="footer-section footer-contact"
          aria-label="Contact and support information"
        >
          <h3>Contact and Support</h3>
          <ul>
            <li>
              <FaPhone aria-hidden="true" />{" "}
              <a href="tel:+255714187068" aria-label="Phone number for Prestige Car Rental">
                +255 712 682 981
              </a>
            </li>
            <li>
              <FaEnvelope aria-hidden="true" />{" "}
              <a
                href="mailto:info@prestigecarrental.co.tz"
                aria-label="Email address for Prestige Car Rental"
              >
                info@prestigecarrental.co.tz
              </a>
            </li>
            <li>
              <FaGlobe aria-hidden="true" />{" "}
              <a
                href="https://www.prestigecarrental.co.tz"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Website of Prestige Car Rental"
              >
                www.prestigecarrental.co.tz
              </a>
            </li>
          </ul>
        </address>

        <div
          className="footer-section footer-about"
          aria-label="About Prestige Car Rental"
        >
          <h3>About Us</h3>
          <hr />
          <p>
            Prestige Car Rental offers a diverse range of rental options,
            including compact cars, SUVs, and scooters, all designed for
            tourists eager to explore Zanzibar’s scenic landscapes. Discover
            hidden beaches and iconic sites with our reliable rentals.
          </p>
          <div className="social-icons" aria-label="Social media links">
            <a
              href="https://facebook.com/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Visit Prestige Car Rental on Facebook"
            >
              <FaFacebook />
            </a>
            <a
              href="https://twitter.com/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Visit Prestige Car Rental on Twitter"
            >
              <FaTwitter />
            </a>
            <a
              href="https://instagram.com/prestigecar_rental"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Visit Prestige Car Rental on Instagram"
            >
              <FaInstagram />
            </a>
          </div>
        </div>

        <div
          className="footer-section footer-visit"
          aria-label="Locations of Prestige Car Rental"
        >
          <h3>Visit Us</h3>
          <hr />
          <br />
          <p>
            <FaMapMarkerAlt aria-hidden="true" /> Vehicle Avenue, Zanzibar,
            Tanzania
          </p>
          <p>
            <FaMapMarkerAlt aria-hidden="true" /> Pemba Airport, Zanzibar,
            Tanzania
          </p>
          <a
            href="https://www.google.com/maps/place/Prestige+Car+Rental/@-6.1576727,39.1910765,17z/data=!3m1!4b1!4m6!3m5!1s0x185cd16a8f8aaae9:0xf793a9e0181455e7!8m2!3d-6.1576727!4d39.1936514!16s%2Fg%2F11y9qhlsft?entry=ttu&g_ep=EgoyMDI0MTIwNC4wIKXMDSoASAFQAw%3D%3D"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Find Prestige Car Rental on Google Maps"
          >
            Find us on Google Maps
          </a>
        </div>

        <div className="footer-bottom">
          <p>
            &copy; {new Date().getFullYear()} Prestige Car Rental. All rights
            reserved. <br />
            Developed and maintained by{" "}
            <a
              href="https://techmento.co.tz"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Visit Techmento Tanzania website"
            >
              Techmento
            </a>
            .
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
