import React from 'react';
import { Helmet } from 'react-helmet';
import Header from "./js/Header";
import HeroSection from "./js/HeroSection";
import HowItWorks from "./js/HowItWorks";
import PopularCars from "./js/PopuarCars";
import FAQComponent from "./js/FaqComponent";
import FactsByTheNumbers from "./js/FactsByNumbers";
import Buttons from "./js/Button";
import WhyChooseUs from "./js/WhyChooseUs";
import Testimonials from "./js/Testimonials";
import Footer from "./js/Footer";

function Home(){
    return(
        <div>
            <Helmet>
                <title>Prestige Car Rental - Rent Cars and Scooters for Your Zanzibar Adventure</title>
                <meta name="description" content="Discover the best car and scooter rental services in Zanzibar. Enjoy convenience, quality vehicles, and reliable support for your trip." />
                <meta name="keywords" content="car rental, scooter rental, Zanzibar, vehicle hire, Zanzibar travel, scooter booking, car rental services" />
                
                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="Zanzibar Motorbikes and Scooter Rental - Rent Cars and Scooters" />
                <meta property="og:description" content="Explore Zanzibar with our top-rated car and scooter rental services. Convenient, affordable, and high-quality vehicles for all your needs." />
                <meta property="og:image" content="path_to_image.jpg" /> {/* Update with actual image path */}
                <meta property="og:url" content="https://prestigecarrental.co.tz" /> {/* Update with actual URL */}
                <meta property="og:type" content="website" />
                
                {/* Twitter Meta Tags */}
                <meta name="twitter:title" content="Zanzibar Motorbikes and Scooter Rental - Rent Cars and Scooters" />
                <meta name="twitter:description" content="Explore Zanzibar with our top-rated car and scooter rental services. Convenient, affordable, and high-quality vehicles for all your needs." />
                <meta name="twitter:image" content="path_to_image.jpg" /> {/* Update with actual image path */}
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            
            <Header />
            <HeroSection />
            <PopularCars />
            <FAQComponent />
            <FactsByTheNumbers />
            <Buttons />
            <WhyChooseUs />
            <Testimonials />
            <Footer />
        </div>
    );
}

export default Home;
