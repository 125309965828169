import React from "react";
import { Helmet } from "react-helmet";
import Header from "../Home/js/Header";
import Footer from "../Home/js/Footer";
import HeroSection from "../Home/js/HeroSection";
import AboutUsContent from "./AboutUsContent";
import Buttons from "../Home/js/Button";

function AboutUs() {
  return (
    <div className="about-us-page">
      {/* Helmet for SEO */}
      <Helmet>
        <title>About Us | Prestige Car Rental - Car & Scooter Rentals in Zanzibar</title>
        <meta
          name="description"
          content="Learn about Prestige Car Rental, your trusted car and scooter rental service in Zanzibar. We are dedicated to providing reliable and affordable rentals."
        />
        <meta
          name="keywords"
          content="car rental Zanzibar, scooter rental Zanzibar, about Prestige Car Rental, Zanzibar car hire, Prestige Car Rental about us"
        />
        <meta property="og:title" content="About Us | Prestige Car Rental" />
        <meta
          property="og:description"
          content="Learn about Prestige Car Rental, your trusted car and scooter rental service in Zanzibar. We are dedicated to providing reliable and affordable rentals."
        />
        <meta
          property="og:image"
          content="https://prestigecarrental.co.tz/assets/images/about-us.jpg"
        />
        <meta property="og:url" content="https://prestigecarrental.co.tz/about-us" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://prestigecarrental.co.tz/about-us" />

        {/* Structured Data for Organization */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Prestige Car Rental",
            "url": "https://prestigecarrental.co.tz",
            "logo": "https://prestigecarrental.co.tz/assets/logo.png",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+255 774 371 987",
              "contactType": "Customer Service",
              "areaServed": "TZ",
              "availableLanguage": "English"
            },
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Zanzibar",
              "addressCountry": "TZ"
            },
            "sameAs": [
              "https://www.facebook.com/PrestigeCarRental",
              "https://www.instagram.com/PrestigeCarRental"
            ]
          })}
        </script>
      </Helmet>

      <header>
        <Header />
      </header>

      <HeroSection 
        title="About Us" 
        backgroundImage="about-hero.jpg" 
        description="Learn more about our journey, values, and commitment to excellence."
      />

      <main>
        <AboutUsContent />
      </main>

      <footer>
        <Footer />
      </footer>

      <div className="buttons-section">
        <Buttons />
      </div>
    </div>
  );
}

export default AboutUs;
