import React from 'react';
import '../css/Button.css';
import { FaWhatsapp, FaArrowUp } from 'react-icons/fa';

const Buttons = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      {/* WhatsApp Button */}
      <a
        href="https://wa.me/+255712682981?text=Hello%20PrestigeCarRental"
        className="whatsapp-button"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Chat with Prestige Car Rental on WhatsApp: The Best Car Rental in Zanzibar"
        itemProp="contactPoint"
        itemType="https://schema.org/ContactPoint"
        tabIndex="0"
      >
        <FaWhatsapp className="whatsapp-icon" aria-hidden="true" />
        <span>How can I help you?</span>
        <meta itemProp="contactType" content="Customer Service" />
        <meta itemProp="telephone" content="+255714187068" />
        <meta itemProp="areaServed" content="TZ" />
        <meta itemProp="availableLanguage" content="English" />
      </a>

      {/* Scroll-to-Top Button */}
      <button
        className="scroll-to-top-button"
        onClick={handleScrollToTop}
        aria-label="Scroll to the top of the page"
        tabIndex="0"
      >
        <FaArrowUp className="arrow-icon" aria-hidden="true" />
      </button>
    </>
  );
};

export default Buttons;
