import React from "react";
import { Helmet } from "react-helmet";
import Header from "../Home/js/Header";
import Footer from "../Home/js/Footer";
import HeroSection from "../Home/js/HeroSection";
import ContactUsContent from "./ContactUsContent";
import Buttons from "../Home/js/Button";

function ContactUs() {
  return (
    <div className="contact-us-page">
      {/* Helmet for SEO */}
      <Helmet>
        <title>Contact Us | Prestige Car Rental - Car & Scooter Rentals in Zanzibar</title>
        <meta
          name="description"
          content="Contact Prestige Car Rental for inquiries or support about car and scooter rentals in Zanzibar. We're here to help 24/7."
        />
        <meta
          name="keywords"
          content="contact Prestige Car Rental, Zanzibar car rentals, scooter rentals Zanzibar, car rental inquiry, scooter rental support"
        />
        <meta property="og:title" content="Contact Us | Prestige Car Rental" />
        <meta
          property="og:description"
          content="Contact Prestige Car Rental for inquiries or support about car and scooter rentals in Zanzibar. We're here to help 24/7."
        />
        <meta
          property="og:image"
          content="https://prestigecarrental.co.tz/assets/images/contact-us.jpg"
        />
        <meta property="og:url" content="https://prestigecarrental.co.tz/contact-us" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://prestigecarrental.co.tz/contact-us" />

        {/* Structured Data for Contact Information */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Prestige Car Rental",
            "url": "https://prestigecarrental.co.tz",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+255 774 371 987",
              "contactType": "Customer Service",
              "areaServed": "TZ",
              "availableLanguage": "English"
            },
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Zanzibar",
              "addressCountry": "TZ"
            },
            "sameAs": [
              "https://www.facebook.com/PrestigeCarRental",
              "https://www.instagram.com/PrestigeCarRental"
            ]
          })}
        </script>
      </Helmet>

      <header>
        <Header />
      </header>

      <HeroSection 
        title="Contact Us" 
        backgroundImage="contact-hero.jpg" 
        description="We're here to assist you! Reach out to Prestige Car Rental for any inquiries or support."
      />

      <main>
        <ContactUsContent />
      </main>

      <footer>
        <Footer />
      </footer>

      <div className="buttons-section">
        <Buttons />
      </div>
    </div>
  );
}

export default ContactUs;
