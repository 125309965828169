import React from 'react';
import { useInView } from 'react-intersection-observer'; // For smooth scroll animations
import { FaUser, FaCar, FaTools, FaRoad } from 'react-icons/fa'; // Using FontAwesome icons
import { Helmet } from 'react-helmet'; // For injecting meta tags into the <head>
import '../css/FactsByNumbers.css'; // Import the CSS file for styles

const FactsByTheNumbers = () => {
  // Intersection Observer hook to detect visibility of each card
  const { ref: happyCustomersRef, inView: happyCustomersInView } = useInView({ triggerOnce: true, threshold: 0.5 });
  const { ref: carsRef, inView: carsInView } = useInView({ triggerOnce: true, threshold: 0.5 });
  const { ref: solutionsRef, inView: solutionsInView } = useInView({ triggerOnce: true, threshold: 0.5 });
  const { ref: kilometersRef, inView: kilometersInView } = useInView({ triggerOnce: true, threshold: 0.5 });

  return (
    <>
      {/* SEO Meta Tags */}
      <Helmet>
        <title>Facts By The Numbers | Prestige Car Rental</title>
        <meta
          name="description"
          content="Explore Prestige Car Rental's remarkable achievements: 1437+ happy customers, 93+ cars, and reliable car rental services in Zanzibar."
        />
        <meta
          name="keywords"
          content="car rental Zanzibar, happy customers, car count, car services, car solutions, Prestige Car Rental"
        />
        <meta name="author" content="Prestige Car Rental" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Facts By The Numbers | Prestige Car Rental" />
        <meta
          property="og:description"
          content="Discover why Prestige Car Rental is trusted: 1437+ satisfied customers, 93+ vehicles, and exceptional service in Zanzibar."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://prestigecarrental.co.tz/facts-by-the-numbers" />
        <meta property="og:image" content="https://prestigecarrental.co.tz/path-to-image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Facts By The Numbers | Prestige Car Rental" />
        <meta
          name="twitter:description"
          content="Numbers that speak volumes: 1437+ happy customers, 93+ cars, and more from Prestige Car Rental."
        />
        <meta name="twitter:image" content="https://prestigecarrental.co.tz/path-to-image.jpg" />
      </Helmet>

      {/* Component Content */}
      <section className="facts-section">
        <div className="facts-header">
          <h1>Facts By The Numbers</h1>
          <p className="subheading">A glimpse into the success and reliability of our services.</p>
        </div>

        <div className="facts-container">
          <div
            ref={happyCustomersRef}
            className={`fact-card ${happyCustomersInView ? 'animate' : ''}`}
          >
            <div className="icon-wrapper">
              <FaUser />
            </div>
            <div className="fact-number">140K+</div>
            <div className="fact-text">Happy Customers</div>
          </div>

          <div
            ref={carsRef}
            className={`fact-card ${carsInView ? 'animate' : ''}`}
          >
            <div className="icon-wrapper">
              <FaCar />
            </div>
            <div className="fact-number">93+</div>
            <div className="fact-text">Count of Cars</div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FactsByTheNumbers;
