import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import { Helmet } from 'react-helmet';
import '../css/Testimonials.css';
import customer1 from '../../../Images/customer1.jpg';
import customer2 from '../../../Images/customer2.jpg';
import customer3 from '../../../Images/customer3.jpg';
import customer4 from '../../../Images/customer4.png';
import customer5 from '../../../Images/customer5.webp';
import customer6 from '../../../Images/customer6.jpg';
import zee from '../../../Images/zee.jpg';

const testimonialsData = [
  {
    name: 'Ngadi Hossin',
    image: zee,
    quote: 'This service was fantastic! The car was in great condition and made my trip around Zanzibar so much fun.',
  },
  {
    name: 'Jane Smith',
    image: customer2,
    quote: 'I had a wonderful experience! The booking process was seamless and the staff was incredibly helpful.',
  },
  {
    name: 'Mark Wilson',
    image: customer3,
    quote: 'Highly recommend! The scooters are top-notch, and the freedom it gives to explore is unmatched.',
  },
  {
    name: 'Emily Johnson',
    image: customer4,
    quote: 'Amazing service! The car was easy to handle, and I loved the flexibility of exploring Zanzibar at my own pace.',
  },
  {
    name: 'Michael Brown',
    image: customer5,
    quote: 'The scooter rental was a highlight of our trip! Friendly staff and very well-maintained scooters. Will use again!',
  },
  {
    name: 'Sophia Martinez',
    image: customer6,
    quote: 'Fantastic experience! Affordable prices and the car were comfortable for long rides. A must-do in Zanzibar!',
  },
];

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlers = useSwipeable({
    onSwipedLeft: () => nextTestimonial(),
    onSwipedRight: () => prevTestimonial(),
  });

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonialsData.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonialsData.length) % testimonialsData.length);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextTestimonial();
    }, 4500);

    return () => clearInterval(interval);
  }, []);

  return (
    <section className="testimonials" {...handlers}>
      <Helmet>
        <title>Customer Testimonials for Our Car and Scooter Rentals in Zanzibar</title>
        <meta name="description" content="Read customer testimonials about their experiences with our top-rated car and scooter rentals in Zanzibar. Explore the beauty of Zanzibar with Prestige Car Rental." />
        <meta name="keywords" content="Zanzibar car rental, scooter rental, customer reviews, travel experiences, Prestige Car Rental, affordable car rentals Zanzibar, best car hire Zanzibar, top-rated car rental services Zanzibar" />
        <meta name="author" content="Prestige Car Rental" />
        
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Customer Testimonials for Our Car and Scooter Rentals in Zanzibar" />
        <meta property="og:description" content="Read customer testimonials about their experiences with our top-rated car and scooter rentals in Zanzibar. Explore the beauty of Zanzibar with Prestige Car Rental." />
        <meta property="og:image" content={customer1} />
        <meta property="og:url" content="https://www.prestigecarrental.co.tz/testimonials" />
        <meta property="og:type" content="article" />
        
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Customer Testimonials for Our Car and Scooter Rentals in Zanzibar" />
        <meta name="twitter:description" content="Read customer testimonials about their experiences with our top-rated car and scooter rentals in Zanzibar. Explore the beauty of Zanzibar with Prestige Car Rental." />
        <meta name="twitter:image" content={customer1} />
        <meta name="twitter:url" content="https://www.prestigecarrental.co.tz/testimonials" />
      </Helmet>
      <h2>Customer Testimonials: Explore Zanzibar with Prestige Car Rental</h2>
      <p className="testimonials-intro">
        Hear what our customers have to say about their experience with our car and scooter rentals in Zanzibar.
      </p>
      <div className="testimonial-slider">
        <div
          className="testimonial-card"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {testimonialsData.map((testimonial, index) => (
            <div
              key={index}
              className={`testimonial-slide ${index === currentIndex ? 'active' : ''}`}
            >
              <img 
                src={testimonial.image} 
                alt={`Customer ${testimonial.name} testimonial`} 
                className="testimonial-image" 
              />
              <p className="testimonial-quote">"{testimonial.quote}"</p>
              <h3 className="testimonial-name">{testimonial.name}</h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
