import React from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet for meta tags
import { useInView } from 'react-intersection-observer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCar } from '@fortawesome/free-solid-svg-icons';
import '../css/HeroSection.css';
import { useNavigate } from 'react-router-dom';

const HeroSection = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger only once
    threshold: 0.1,    // Start the animation when 10% of the section is visible
  });
  const navigate = useNavigate();

  const handleBookNow = () => {
    navigate("/booking")
  };

  return (
    <section className={`hero-section ${inView ? 'slide-down' : ''}`} ref={ref}>
      {/* Helmet for SEO */}
      <Helmet>
        <title>Affordable Car Rentals in Zanzibar - Prestige Car Rental</title>
        <meta
          name="description"
          content="Rent a car from Prestige Car Rental in Zanzibar for just $25/day. Trusted and budget-friendly options for your perfect journey."
        />
        <meta
          name="keywords"
          content="Zanzibar car rental, affordable car rentals Zanzibar, Prestige Car Rental, trusted car hire Zanzibar"
        />
        <meta name="author" content="Prestige Car Rental" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:title" content="Affordable Car Rentals in Zanzibar" />
        <meta
          property="og:description"
          content="Find your perfect car for rental from Prestige Car Rental. Trusted, affordable options starting at $25/day."
        />
        <meta
          property="og:url"
          content="https://www.prestigecarrental.co.tz"
        />
        <meta
          property="og:image"
          content="https://www.prestigecarrental.co.tz/hero-section-image.jpg"
        />
        <meta property="og:type" content="website" />
        
        {/* Twitter Meta Tags */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content="Affordable Car Rentals in Zanzibar" />
        <meta property="twitter:description" content="Find your perfect car for rental from Prestige Car Rental. Trusted, affordable options starting at $25/day." />
        <meta property="twitter:image" content="https://www.prestigecarrental.co.tz/hero-section-image.jpg" />
      </Helmet>

      <div className="hero-content">
        <h2 className="hero-title">
          <FontAwesomeIcon icon={faCheckCircle} className="icon" /> 100% Trusted Car Rental Platform in Zanzibar
        </h2>
        <h1 className="hero-heading">Find Your Best Car for Rental from $25</h1>
        <p className="hero-description">
          Welcome to Prestige Car Rental! Discover your perfect, budget-friendly rental car. Browse our selection of affordable options to find the ideal vehicle for your next journey.
        </p>
        <button className="book-now-btn" onClick={handleBookNow}>Book Now</button>
        <div className="hero-icons">
          <FontAwesomeIcon icon={faCar} className="car-icon" />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
