import React, { useState } from 'react';
import { Helmet } from 'react-helmet'; // Importing Helmet for meta tags
import '../css/Header.css'; // Import your CSS file here
import logo from '../../../Images/logo.png';
import { FaInstagram, FaFacebookF, FaTwitter, FaWhatsapp } from 'react-icons/fa'; // Importing social media icons

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  // Toggle the sidebar menu
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div>
      {/* Helmet for SEO */}
      <Helmet>
        <title>PrestigeCarRental - Explore Zanzibar with Ease</title>
        <meta
          name="description"
          content="Discover Prestige Car Rental for reliable car and scooter rentals in Zanzibar. Book now for an unforgettable travel experience."
        />
        <meta
          name="keywords"
          content="Zanzibar car rentals, scooter rentals Zanzibar, Prestige Car Rental, car hire Zanzibar, travel Zanzibar"
        />
        <meta name="author" content="Prestige Car Rental" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          rel="canonical"
          href="https://www.prestigecarrental.co.tz"
        />
        <meta property="og:title" content="Prestige Car Rental" />
        <meta
          property="og:description"
          content="Reliable car and scooter rentals in Zanzibar. Explore the island with comfort and flexibility."
        />
        <meta
          property="og:url"
          content="https://www.prestigecarrental.co.tz"
        />
        <meta
          property="og:image"
          content="https://www.prestigecarrental.co.tz/og-image.jpg"
        />
        <meta property="og:type" content="website" />
      </Helmet>

      {/* Header Section */}
      <header className="header">
        <div className="logo">
          <img src={logo} alt="Prestige Car Rental Logo" />
        </div>

        {/* Desktop Navigation */}
        <div className={`nav-links ${menuOpen ? 'open' : ''}`}>
          <a href="/">Home</a>
          <a href="/aboutUs">About Us</a>
          <a href="/booking">Booking</a>
          <a href="/contactUs">Contact</a>
        </div>

        {/* Mobile Menu Icon */}
        <div className="menu-icon" onClick={toggleMenu}>
          <div className="menu-bar"></div>
          <div className="menu-bar"></div>
          <div className="menu-bar"></div>
        </div>
      </header>

      {/* Sidebar for Mobile */}
      <div className={`sidebar ${menuOpen ? 'open' : ''}`}>
        <div className="sidebar-close" onClick={toggleMenu}>
          &times;
        </div>

        {/* Logo Inside Sidebar */}
        <div className="sidebar-logo">
          <img src={logo} alt="Prestige Car Rental Logo" />
        </div>

        {/* Sidebar Links */}
        <a href="/" onClick={toggleMenu}>Home</a>
        <a href="/aboutUs" onClick={toggleMenu}>About Us</a>
        <a href="/booking" onClick={toggleMenu}>Booking</a>
        <a href="/contactUs" onClick={toggleMenu}>Contact</a>

        {/* Social Media Icons */}
        <div className="social-icons">
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
            <FaInstagram />
          </a>
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
            <FaFacebookF />
          </a>
          <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
            <FaTwitter />
          </a>
          <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer" aria-label="WhatsApp">
            <FaWhatsapp />
          </a>
        </div>

        {/* Copyright Message */}
        <div className="sidebar-footer">
          &copy; 2024 Prestige Car Rental. All Rights Reserved.
          <br />
          <div className="techmento">
            Developed &nbsp; by <a href="https://techmento.co.tz" target="_blank" rel="noopener noreferrer">Techmento</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

